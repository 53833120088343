import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import PriceChart from '../components/charts/PriceChart'; // Keeping the chart for price history
import NavbarWithSearch from '../components/navbars/navbar';


// Assuming the image is in the 'public' folder, or adjust path accordingly
import imagePathHero from '../assets/pond.jpg'; 
import imagePathLabel from '../assets/ArtistFlow.mp4';
import imagePathInvestor from '../assets/InvestorFlow.mp4';
import imagePathWhyGoPublic from '../assets/ArtistFlow.mp4';


const HomePage = () => {
  const [artists, setArtists] = useState([]);   // All available artists
  const [filteredArtists, setFilteredArtists] = useState([]); // Filtered artists for search
  const [investors, setInvestors] = useState([]);   // All available investors
  const [loading, setLoading] = useState(true); // Loading indicator
  const [error, setError] = useState(null);     // Error handling
  const [searchQuery, setSearchQuery] = useState(''); // Search query for filtering artists
  const navigate = useNavigate(); // Initialize navigate here

  // Fetch artists and investors from the backend
  useEffect(() => {
    const fetchArtistsAndInvestors = async () => {
      try {
        const artistResponse = await axios.get('/api/artists');
        setArtists(artistResponse.data);
        setFilteredArtists(artistResponse.data); // Set the filtered artists initially as all artists
        const investorResponse = await axios.get('/api/investors');
        setInvestors(investorResponse.data);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch artists and investors.');
        setLoading(false);
      }
    };
    fetchArtistsAndInvestors();
  }, []);

  // Handle artist search
  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    setFilteredArtists(
      artists.filter(artist => artist.name.toLowerCase().includes(query))
    );
  };

  // Function to get the latest share price from priceHistory array
  const getLatestSharePrice = (priceHistory) => {
    if (!priceHistory || priceHistory.length === 0) return 'N/A';
    return priceHistory[priceHistory.length - 1].price;
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  
return (
<div className="min-h-screen bg-base-100">
  <NavbarWithSearch />
  
  <div className="container mx-auto px-4">
    <div className="relative h-[50vh] md:h-[70vh] w-full bg-cover bg-center rounded-lg" style={{ backgroundImage: `url(${imagePathHero})` }}>
      <div className="absolute inset-0 flex flex-col justify-center items-center text-white">
        <h1 className="text-4xl font-bold mb-4">Invest in Talent. Own a Piece of the Future.</h1>
        <p className="mb-8 text-lg">Buy and Trade shares in your favorite artists and support their journey to success.</p>
        <a href="#how-it-works-labels" className="btn btn-primary">How it works</a>
        <a href="#why-go-public" className="btn btn-primary mt-4">Why go public with an Artist</a>
      </div>
    </div>

    <div className="container mx-auto px-4 mt-16">
      <h2 className="text-2xl font-semibold mb-4">Exemplary Artists</h2>
      <ul className="space-y-6">
        {filteredArtists.map((artist) => (
          <li key={artist._id} className="p-4 bg-white rounded-lg shadow-none card">
            <h3 className="text-lg font-bold">{artist.name}</h3>
            <p className="text-sm">Latest Share Price: €{getLatestSharePrice(artist.priceHistory)}</p>

            <div className="my-4">
              <PriceChart priceHistory={artist.priceHistory} />
            </div>

            <Link to={`/artist/detail/${artist._id}`}>
              <button className="btn btn-primary mt-4 hidden">Invest in {artist.name}</button>
            </Link>
          </li>
        ))}
      </ul>
    </div>

    <div id="why-go-public" className="container mx-auto px-4 mt-16 grid grid-cols-1 md:grid-cols-2 gap-8 pb-16 scroll-mt-24">
      <div className="flex justify-center items-center order-1 md:order-none">
        <div className="mockup-phone border-primary max-w-full max-h-full"> 
          <div className="camera"></div>
          <div className="display">
            <div className="artboard artboard-demo phone-1">
            <video className="w-full h-full object-cover rounded-lg" autoPlay muted loop playsInline>
                <source src={imagePathWhyGoPublic} type="video/mp4" />
                 Your browser does not support the video tag.
            </video> 
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-center order-2 md:order-none">
        <h2 className="text-3xl font-semibold mb-8">Why Go Public with Your Artist?</h2>

        <div className="space-y-4 text-sm">
          <div>
            <h3 className="text-xl font-bold mb-2">Raise Capital for Growth</h3>
            <p>Going public with an artist allows record labels to raise funding to invest in music production, marketing, tours, and other growth opportunities.</p>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-2">Engage Fans as Shareholders</h3>
            <p>By offering fans a chance to own shares in an artist's career, labels create a deeper connection with the audience.</p>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-2">Share Risk, Maximize Return</h3>
            <p>Going public helps labels distribute the financial risk of artist development while potentially maximizing returns if the artist succeeds.</p>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-2">Leverage New Finance Streams</h3>
            <p>Listing an artist on the platform opens up new finance streams beyond traditional record sales, streaming, and tours.</p>
          </div>
        </div>
      </div>
    </div>

    <div id="how-it-works-labels" className="container mx-auto px-4 mt-16 pb-16 scroll-mt-24 grid grid-cols-1 md:grid-cols-2 gap-8">
      <div className="flex flex-col justify-center order-2 md:order-none">
        <h2 className="text-3xl font-semibold mb-8">How It Works for Record Labels</h2>
        <div className="space-y-4 text-sm">
          <div>
            <h3 className="text-xl font-bold mb-2">List Your Artist</h3>
            <p>Start by listing your artist on the platform. Provide essential details about their career, future plans, and financial needs.</p>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-2">Offer Shares</h3>
            <p>Set a number of shares for your artist’s career and determine the initial share price. These shares represent a portion of future earnings.</p>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-2">Raise Funds</h3>
            <p>As fans and investors purchase shares, you raise capital for the artist’s development. Use these funds to support album production, tours, marketing, or other creative projects.</p>
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center order-1 md:order-none">
        <div className="mockup-phone border-primary max-w-full max-h-full"> 
          <div className="camera"></div>
          <div className="display">
            <div className="artboard artboard-demo phone-1">
              <video className="w-full h-full object-cover rounded-lg" autoPlay muted loop playsInline>
                <source src={imagePathLabel} type="video/mp4" />
                Your browser does not support the video tag.
            </video> 
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="how-it-works-investors" className="container mx-auto px-4 mt-16 grid grid-cols-1 md:grid-cols-2 gap-8 pb-32">
      <div className="flex justify-center items-center order-1 md:order-none">
        <div className="mockup-phone border-primary max-w-full max-h-full"> 
          <div className="camera"></div>
          <div className="display">
            <div className="artboard artboard-demo phone-1">
              <video className="w-full h-full object-cover rounded-lg" autoPlay muted loop playsInline>
                <source src={imagePathInvestor} type="video/mp4" />
                 Your browser does not support the video tag.
            </video> 
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-center order-2 md:order-none">
        <h2 className="text-3xl font-semibold mb-8">How It Works for Investors</h2>
        <div className="space-y-4 text-sm">
          <div>
            <h3 className="text-xl font-bold mb-2">Discover Artists</h3>
            <p>Explore talented artists and learn more about their vision, projects, and future potential.</p>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-2">Buy Shares</h3>
            <p>Purchase shares in your favorite artists and own a piece of their success.</p>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-2">Trade and Earn</h3>
            <p>As the artist grows, your shares can increase in value. Trade with other investors or hold for the future.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



);

};
export default HomePage;






//v1.1 Homepage Design 2
/*import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import PriceChart from '../components/charts/PriceChart'; // Keeping the chart for price history
import NavbarWithSearch from '../components/navbars/navbar';


// Assuming the image is in the 'public' folder, or adjust path accordingly
import imagePathHero from '../assets/pond.jpg'; 
import imagePathLabel from '../assets/ArtistPage.png';
import imagePathInvestor from '../assets/InvestorPage.png';
import imagePathWhyGoPublic from '../assets/pond.jpg';


const HomePage = () => {
  const [artists, setArtists] = useState([]);   // All available artists
  const [filteredArtists, setFilteredArtists] = useState([]); // Filtered artists for search
  const [investors, setInvestors] = useState([]);   // All available investors
  const [loading, setLoading] = useState(true); // Loading indicator
  const [error, setError] = useState(null);     // Error handling
  const [searchQuery, setSearchQuery] = useState(''); // Search query for filtering artists
  const navigate = useNavigate(); // Initialize navigate here

  // Fetch artists and investors from the backend
  useEffect(() => {
    const fetchArtistsAndInvestors = async () => {
      try {
        const artistResponse = await axios.get('/api/artists');
        setArtists(artistResponse.data);
        setFilteredArtists(artistResponse.data); // Set the filtered artists initially as all artists
        const investorResponse = await axios.get('/api/investors');
        setInvestors(investorResponse.data);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch artists and investors.');
        setLoading(false);
      }
    };
    fetchArtistsAndInvestors();
  }, []);

  // Handle artist search
  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    setFilteredArtists(
      artists.filter(artist => artist.name.toLowerCase().includes(query))
    );
  };

  // Function to get the latest share price from priceHistory array
  const getLatestSharePrice = (priceHistory) => {
    if (!priceHistory || priceHistory.length === 0) return 'N/A';
    return priceHistory[priceHistory.length - 1].price;
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  
return (
<div className="min-h-screen bg-base-100">
  <NavbarWithSearch />
  
  <div className="container mx-auto px-4">
    <div className="relative h-[50vh] md:h-[70vh] w-full bg-cover bg-center rounded-lg" style={{ backgroundImage: `url(${imagePathHero})` }}>
      <div className="absolute inset-0 flex flex-col justify-center items-center text-white">
        <h1 className="text-4xl font-bold mb-4">Invest in Talent. Own a Piece of the Future.</h1>
        <p className="mb-8 text-lg">Buy and Trade shares in your favorite artists and support their journey to success.</p>
        <a href="#how-it-works-labels" className="btn btn-primary">How it works</a>
        <a href="#why-go-public" className="btn btn-primary mt-4">Why go public with an Artist</a>
      </div>
    </div>

    <div className="container mx-auto px-4 mt-16">
      <h2 className="text-2xl font-semibold mb-4">Exemplary Artists</h2>
      <ul className="space-y-6">
        {filteredArtists.map((artist) => (
          <li key={artist._id} className="p-4 bg-white rounded-lg shadow-none card">
            <h3 className="text-lg font-bold">{artist.name}</h3>
            <p className="text-sm">Latest Share Price: €{getLatestSharePrice(artist.priceHistory)}</p>

            <div className="my-4">
              <PriceChart priceHistory={artist.priceHistory} />
            </div>

            <Link to={`/artist/detail/${artist._id}`}>
              <button className="btn btn-primary mt-4 hidden">Invest in {artist.name}</button>
            </Link>
          </li>
        ))}
      </ul>
    </div>

    <div id="why-go-public" className="container mx-auto px-4 mt-16 grid grid-cols-1 md:grid-cols-2 gap-8 pb-16 scroll-mt-24">
      <div className="flex justify-center items-center order-1 md:order-none">
        <div className="mockup-phone border-primary max-w-full max-h-full"> 
          <div className="camera"></div>
          <div className="display">
            <div className="artboard artboard-demo phone-1">
              <img src={imagePathWhyGoPublic} alt="Why Go Public" className="object-cover rounded-lg shadow-lg" />
            </div>
          </div>
        </div>
      </div>

      <div className="text-left order-2 md:order-none">
        <h2 className="text-3xl font-semibold mb-8">Why Go Public with Your Artist?</h2>

        <div className="space-y-4 text-sm">
          <div>
            <h3 className="text-xl font-bold mb-2">Raise Capital for Growth</h3>
            <p>Going public with an artist allows record labels to raise funding to invest in music production, marketing, tours, and other growth opportunities.</p>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-2">Engage Fans as Shareholders</h3>
            <p>By offering fans a chance to own shares in an artist's career, labels create a deeper connection with the audience.</p>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-2">Share Risk, Maximize Return</h3>
            <p>Going public helps labels distribute the financial risk of artist development while potentially maximizing returns if the artist succeeds.</p>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-2">Leverage New Finance Streams</h3>
            <p>Listing an artist on the platform opens up new finance streams beyond traditional record sales, streaming, and tours.</p>
          </div>
        </div>
      </div>
    </div>

    <div id="how-it-works-labels" className="container mx-auto px-4 mt-16 pb-16 scroll-mt-24 grid grid-cols-1 md:grid-cols-2 gap-8">
      <div className="flex flex-col justify-center order-2 md:order-none">
        <h2 className="text-3xl font-semibold mb-8">How It Works for Record Labels</h2>
        <div className="space-y-4 text-sm">
          <div>
            <h3 className="text-xl font-bold mb-2">List Your Artist</h3>
            <p>Start by listing your artist on the platform. Provide essential details about their career, future plans, and financial needs.</p>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-2">Offer Shares</h3>
            <p>Set a number of shares for your artist’s career and determine the initial share price. These shares represent a portion of future earnings.</p>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-2">Raise Funds</h3>
            <p>As fans and investors purchase shares, you raise capital for the artist’s development. Use these funds to support album production, tours, marketing, or other creative projects.</p>
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center order-1 md:order-none">
        <div className="mockup-phone border-primary max-w-full max-h-full"> 
          <div className="camera"></div>
          <div className="display">
            <div className="artboard artboard-demo phone-1">
              <img src={imagePathLabel} alt="Record Labels" className="object-cover rounded-lg shadow-lg" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="how-it-works-investors" className="container mx-auto px-4 mt-16 grid grid-cols-1 md:grid-cols-2 gap-8 pb-32">
      <div className="flex justify-center items-center order-1 md:order-none">
        <div className="mockup-phone border-primary max-w-full max-h-full"> 
          <div className="camera"></div>
          <div className="display">
            <div className="artboard artboard-demo phone-1">
              <img src={imagePathInvestor} alt="Investors" className="object-cover rounded-lg shadow-lg" />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-center order-2 md:order-none">
        <h2 className="text-3xl font-semibold mb-8">How It Works for Investors</h2>
        <div className="space-y-4 text-sm">
          <div>
            <h3 className="text-xl font-bold mb-2">Discover Artists</h3>
            <p>Explore talented artists and learn more about their vision, projects, and future potential.</p>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-2">Buy Shares</h3>
            <p>Purchase shares in your favorite artists and own a piece of their success.</p>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-2">Trade and Earn</h3>
            <p>As the artist grows, your shares can increase in value. Trade with other investors or hold for the future.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



);

};
export default HomePage;*/
