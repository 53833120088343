import React from 'react';
import './styles.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ArtistDetailPage from './pages/ArtistDetailPage';
import InvestorPage from './pages/InvestorPage';
import ArtistProfilePage from './pages/ArtistProfilePage';
import AdminPage from './pages/AdminPage';
import Orders from './pages/Orders';



function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />        
        <Route path="/artist/detail/:id" element={<ArtistDetailPage />} />
        <Route path="/artist/profile/:id" element={<ArtistProfilePage />} />
        <Route path="/investor/:id" element={<InvestorPage />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/orders" element={<Orders />} />
      </Routes>
    </Router>
  );
}

export default App;



//v0.2App.js with static InvestorPage
/*import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ArtistDetailPage from './pages/ArtistDetailPage';
import InvestorPage from './pages/InvestorPage';
import AdminPage from './pages/AdminPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />        
        <Route path="/artist/:id" element={<ArtistDetailPage />} />
        <Route path="/investor" element={<InvestorPage />} />
        <Route path="/admin" element={<AdminPage />} />
      </Routes>
    </Router>
  );
}

export default App;*/


//v0.1
/*import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ArtistDetailPage from './pages/ArtistDetailPage';
import InvestorPage from './pages/InvestorPage';
import ArtistManagement from './pages/AdminPage'; // Import ArtistManagement

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />        
        <Route path="/artist/:id" element={<ArtistDetailPage />} />
        <Route path="/investor" element={<InvestorPage />} />
        <Route path="/admin" element={<ArtistManagement />} />
      </Routes>
    </Router>
  );
}

export default App;*/
