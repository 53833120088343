import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import PortfolioValueChart from '../components/charts/PortfolioValueChart';
import OrderModal from '../components/modals/OrderModal';
import NavbarWithSearch from '../components/navbars/navbar';

const InvestorPage = () => {
  const { id } = useParams(); // Get the investor ID from URL
  const [investor, setInvestor] = useState(null); // Store investor data
  const [artists, setArtists] = useState([]); // Store list of available artists for orders
  const [error, setError] = useState(null);
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false); // State to manage modal visibility

  // Fetch investor data
  useEffect(() => {
    const fetchInvestor = async () => {
      try {
        const response = await axios.get(`/api/investors/${id}`);
        setInvestor(response.data);
      } catch (err) {
        setError('Failed to fetch investor');
      }
    };

    const fetchArtists = async () => {
      try {
        const response = await axios.get('/api/artists');
        setArtists(response.data);
      } catch (err) {
        console.error('Failed to fetch artists');
      }
    };

    if (id) {
      fetchInvestor();
      fetchArtists();
    }
  }, [id]);

  // Calculate total portfolio value (current)
  const calculatePortfolioValue = () => {
    if (!investor || !investor.portfolio || investor.portfolio.length === 0) return 0;
    return investor.portfolio.reduce((total, artist) => {
      return total + (artist.sharesOwned * (artist.artistId?.pricePerShare || 0));
    }, 0);
  };

  // Handle order submission
  const handleOrderSubmit = async (order) => {
    const endpoint = order.type === 'buy' ? 'buy' : 'sell';
    try {
      await axios.post(`/api/orders/${endpoint}`, {
        investorId: id,
        artistId: order.artistId,
        shares: order.shares,
        price: order.price,
      });
      alert(`${order.type.charAt(0).toUpperCase() + order.type.slice(1)} order placed successfully`);
    } catch (err) {
      console.error(`Failed to place ${order.type} order:`, err);
    }
  };

  if (error) return <p className="text-red-500">{error}</p>;
  if (!investor) return <p>Loading...</p>;

  return (
<div className="min-h-screen bg-base-100 overflow-hidden">
  {/* Use the NavbarWithSearch component */}
  <NavbarWithSearch />

  <div className="container mx-auto p-4 max-w-full">
    <h1 className="text-3xl font-bold mb-4">Investor Wallet</h1>
    <h2 className="text-lg font-semibold">{investor.name}</h2>
    <h2 className="text-xs font-semibold">ID: {investor._id}</h2>

    {/* Portfolio Summary */}
    <h3 className="text-xl font-semibold mt-6">Total Value: €{calculatePortfolioValue()}</h3>

    {/* Portfolio Chart */}
    <div className="my-6 w-full">
      <PortfolioValueChart portfolioValueHistory={investor.portfolioValueHistory} />
    </div>

    {/* Display Portfolio */}
    <h3 className="text-xl font-semibold mb-4">Portfolio</h3>
    {investor.portfolio && investor.portfolio.length > 0 ? (
      <div className="overflow-x-auto w-full">
        <table className="table table-auto w-full min-w-full">
          <thead>
            <tr>
              <th>Artist</th>
              <th>Shares Owned</th>
              <th>Price per Share (€)</th>
            </tr>
          </thead>
          <tbody>
            {investor.portfolio.map((artist, index) => (
              <tr key={index}>
                <td>
                  <div className="flex items-center space-x-3">
                    <div className="avatar">
                      <div className="w-8 h-8 rounded-full">
                        <img
                          src={`https://api.dicebear.com/5.x/avataaars/svg?seed=${artist.artistId?.name || 'Unknown'}`}
                          alt="Artist Avatar"
                        />
                      </div>
                    </div>
                    <div>
                      <span className="font-semibold">{artist.artistId ? artist.artistId.name : 'Unknown Artist'}</span>
                    </div>
                  </div>
                </td>
                <td>{artist.sharesOwned}</td>
                <td>€{artist.artistId?.pricePerShare || 'N/A'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ) : (
      <p className="text-gray-500">No investments found.</p>
    )}

    {/* Display Investor's Transaction Log */}
    <h3 className="text-xl font-semibold mb-4">Transaction Log</h3>
    {investor.transactionLog && investor.transactionLog.length > 0 ? (
      <div className="overflow-x-auto w-full">
        <table className="table table-auto w-full min-w-full table-zebra">
          <thead>
            <tr>
              <th>Artist</th>
              <th>Shares Bought</th>
              <th>Shares Sold</th>
              <th>Price at Purchase</th>
              <th>Price at Sale</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {investor.transactionLog.map((transaction, index) => (
              <tr key={index}>
                <td>{transaction.artistId ? transaction.artistId.name : 'Unknown Artist'}</td>
                <td>{transaction.sharesBought}</td>
                <td>{transaction.sharesSold}</td>
                <td>€{transaction.priceAtPurchase}</td>
                <td>€{transaction.priceAtSale}</td>
                <td>{new Date(transaction.date).toLocaleDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ) : (
      <p className="text-gray-500">No transactions found.</p>
    )}

    {/* Button to open order modal */}
    <button
      onClick={() => setIsOrderModalOpen(true)}
      className="btn btn-primary fixed bottom-4 left-4 z-50"
    >
      Place Order
    </button>

    {/* Order Modal */}
    <OrderModal
      isOpen={isOrderModalOpen}
      onRequestClose={() => setIsOrderModalOpen(false)}
      artists={artists}
      investorId={id}
      handleOrderSubmit={handleOrderSubmit}
    />
  </div>
</div>
  );
};

export default InvestorPage;


