//v1.0 ADP with first UI

import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import PriceChart from '../components/charts/PriceChart';
import OrderModal from '../components/modals/OrderModal';

const ArtistDetailPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [artist, setArtist] = useState(null);
  const [error, setError] = useState(null);
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);

  const fetchArtist = useCallback(async () => {
    try {
      const response = await axios.get(`/api/artists/detail/${id}`);
      setArtist(response.data);
    } catch (error) {
      setError('Failed to fetch artist data');
    }
  }, [id]);

  useEffect(() => {
    fetchArtist();
  }, [fetchArtist]);

  // Handle order submission
  const handleOrderSubmit = async (order) => {
    const endpoint = order.type === 'buy' ? 'buy' : 'sell';
    try {
      await axios.post(`/api/orders/${endpoint}`, {
        investorId: id,
        artistId: order.artistId,
        shares: order.shares,
        price: order.price,
      });
      alert(`${order.type.charAt(0).toUpperCase() + order.type.slice(1)} order placed successfully`);
    } catch (err) {
      console.error(`Failed to place ${order.type} order:`, err);
    }
  };

  if (error) return <p className="text-red-500">{error}</p>;
  if (!artist) return <p>Loading...</p>;

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">{artist.name}</h1>
      <p className="text-lg mb-4">{artist.bio}</p>

      <div className="my-6">
        <PriceChart priceHistory={artist.priceHistory} />
      </div>

      <div className="flex space-x-4">
        <button onClick={() => navigate('/')} className="btn btn-secondary">Back to Home Page</button>
        <button onClick={() => setIsOrderModalOpen(true)} className="btn btn-primary">Place an Order</button>
      </div>

      {/* Order Modal */}
      {isOrderModalOpen && (
        <OrderModal 
          artist={artist}
          onClose={() => setIsOrderModalOpen(false)}
          onSubmit={handleOrderSubmit}
        />
      )}
    </div>
  );
};

export default ArtistDetailPage;
