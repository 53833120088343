//v1.0 OrderModal as DaisyUI Component
import React, { useState } from 'react';

const OrderModal = ({ isOpen, onRequestClose, artists, investorId, handleOrderSubmit }) => {
  const [order, setOrder] = useState({ artistId: '', shares: 0, price: 0, type: 'buy' });

  const handleSubmit = async (e) => {
    e.preventDefault();
    await handleOrderSubmit(order);
    onRequestClose(); // Close the modal after submission
  };

  return (
    <dialog id="order_modal" className={`modal ${isOpen ? 'modal-open' : ''}`}>
      <div className="modal-box">
        <h2 className="font-bold text-lg mb-4">Place a {order.type === 'buy' ? 'Buy' : 'Sell'} Order</h2>

        <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-4">
          {/* Artist selection */}
          <div className="form-control">
            <label className="label">
              <span className="label-text">Artist:</span>
            </label>
            <select
              value={order.artistId}
              onChange={(e) => setOrder({ ...order, artistId: e.target.value })}
              required
              className="select select-bordered w-full"
            >
              <option value="">Select Artist</option>
              {artists.map((artist) => (
                <option key={artist._id} value={artist._id}>
                  {artist.name}
                </option>
              ))}
            </select>
          </div>

          {/* Shares input */}
          <div className="form-control">
            <label className="label">
              <span className="label-text">Shares:</span>
            </label>
            <input
              type="number"
              value={order.shares}
              onChange={(e) => setOrder({ ...order, shares: e.target.value })}
              required
              className="input input-bordered"
              min="1"
            />
          </div>

          {/* Price input */}
          <div className="form-control">
            <label className="label">
              <span className="label-text">Price:</span>
            </label>
            <input
              type="number"
              value={order.price}
              onChange={(e) => setOrder({ ...order, price: e.target.value })}
              required
              className="input input-bordered"
              min="0"
            />
          </div>

          {/* Order Type */}
          <div className="form-control">
            <label className="label">
              <span className="label-text">Order Type:</span>
            </label>
            <select
              value={order.type}
              onChange={(e) => setOrder({ ...order, type: e.target.value })}
              className="select select-bordered"
            >
              <option value="buy">Buy</option>
              <option value="sell">Sell</option>
            </select>
          </div>

          {/* Submit button */}
          <div className="modal-action">
            <button type="submit" className="btn btn-primary">Submit Order</button>
            <button type="button" onClick={onRequestClose} className="btn">Close</button>
          </div>
        </form>
      </div>
    </dialog>
  );
};

export default OrderModal;



//v0.1 Order Model with React Component
/*import React from 'react';
import { useState } from 'react';
import Modal from 'react-modal'; // You will need to install react-modal using npm install react-modal

const OrderModal = ({ isOpen, onRequestClose, artists, investorId, handleOrderSubmit }) => {
  const [order, setOrder] = useState({ artistId: '', shares: 0, price: 0, type: 'buy' });

  const handleSubmit = async (e) => {
    e.preventDefault();
    await handleOrderSubmit(order);
    onRequestClose(); // Close the modal after submission
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} contentLabel="Order Modal">
      <h2>Place a {order.type === 'buy' ? 'Buy' : 'Sell'} Order</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Artist:
          <select value={order.artistId} onChange={(e) => setOrder({ ...order, artistId: e.target.value })} required>
            <option value="">Select Artist</option>
            {artists.map(artist => (
              <option key={artist._id} value={artist._id}>
                {artist.name}
              </option>
            ))}
          </select>
        </label>
        <label>
          Shares:
          <input type="number" value={order.shares} onChange={(e) => setOrder({ ...order, shares: e.target.value })} required />
        </label>
        <label>
          Price:
          <input type="number" value={order.price} onChange={(e) => setOrder({ ...order, price: e.target.value })} required />
        </label>
        <label>
          Order Type:
          <select value={order.type} onChange={(e) => setOrder({ ...order, type: e.target.value })}>
            <option value="buy">Buy</option>
            <option value="sell">Sell</option>
          </select>
        </label>
        <button type="submit">Submit Order</button>
      </form>
      <button onClick={onRequestClose}>Close</button>
    </Modal>
  );
};

export default OrderModal;*/
