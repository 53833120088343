import React from 'react';
import ArtistSearch from '../searches/ArtistSearch'; // Import ArtistSearch
import imagePathLogo from '../../assets/pond.jpg'; // Assuming you have a logo image

const NavbarWithSearch = () => {
  return (
   <header className="navbar bg-base-100 sticky top-0 py-1 sm:py-1 z-50">
  <div className="container mx-auto flex flex-col sm:flex-row justify-between items-center space-y-2 sm:space-y-0 pl-4 sm:pl-0">
    
    {/* Left Side: Logo and Artradium Button */}
    <div className="flex w-full sm:w-auto justify-between sm:justify-start items-center">
      
      {/* Logo and Home Button */}
      <div className="flex items-center">
        <a className="btn btn-ghost font-bold text-2xl">Artradium</a>
      </div>
      
      {/* Search Field: Adjacent to Artradium button */}
      <div className="form-control w-full sm:w-80 ml-4">
        <ArtistSearch />
      </div>
    </div>

    
  </div>
</header>

  );
};

export default NavbarWithSearch;




//v1.1

/*
import React from 'react';
import ArtistSearch from '../searches/ArtistSearch'; // Import ArtistSearch

import imagePathLogo from '../../assets/pond.jpg';


const NavbarWithSearch = () => {
  return (
    <header className="navbar bg-base-100 sticky top-0 mb-8 py-4 z-10">
      <div className="container mx-auto flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
        
        <div className="flex flex-col sm:flex-row items-center w-full sm:w-auto">
          <img src={imagePathLogo} alt="Logo" className="w-10 h-10 object-cover rounded-full" /> 
          
          <a className="btn btn-ghost font-bold text-2xl sm:mr-4">Artradium</a>

          <div className="form-control w-full sm:w-80">
            <ArtistSearch />
          </div>
        </div>

        <div className="flex-none w-full sm:w-auto text-center sm:text-right">
          <ul className="menu menu-horizontal w-full sm:w-auto px-1 font-bold text-sm sm:text-base">
            <li><a href="/signup" className="hidden">Sign Up</a></li>
          </ul>
        </div>
      </div>
    </header>

  );
};

export default NavbarWithSearch;*/



//v1.0
/*import React from 'react';
import ArtistSearch from '../searches/ArtistSearch'; // Import ArtistSearch


const NavbarWithSearch = () => {
  return (
    <header className="navbar bg-base-100 sticky top-0 mb-8 py-0 h-8 z-10">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex items-center">
          <a className="btn btn-ghost font-bold text-2xl mr-4">Artradium</a>

          <div className="form-control w-80">
            <ArtistSearch /> 
          </div>
        </div>

        <div className="flex-none">
          <ul className="menu menu-horizontal px-1 font-bold text-sm">
            <li><a href="/signup">Sign Up</a></li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default NavbarWithSearch;*/
