//v1.0 Admin Page + UI

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import NavbarWithSearch from '../components/navbars/navbar';


const AdminPage = () => {
  const [artists, setArtists] = useState([]);
  const [investors, setInvestors] = useState([]);
  const [transactionLog, setTransactionLog] = useState([]); // Central transaction log
  const [error, setError] = useState(null);

  const [name, setName] = useState('');
  const [bio, setBio] = useState('');
  const [sharesAvailable, setSharesAvailable] = useState('');  // New field for shares available
  const [pricePerShare, setPricePerShare] = useState('');  // New field for price per share
  const [newInvestorName, setNewInvestorName] = useState('');
  const navigate = useNavigate(); // Initialize navigate here

  // Fetch artists from the backend
  const fetchArtists = async () => {
    try {
      const response = await axios.get('/api/artists');
      setArtists(response.data);
    } catch (error) {
      setError('Failed to fetch artists');
    }
  };

  // Fetch investors from the backend
  const fetchInvestors = async () => {
    try {
      const response = await axios.get('/api/investors');
      setInvestors(response.data);
    } catch (error) {
      setError('Failed to fetch investors');
    }
  };

  // Fetch all transactions from the central transaction log
  const fetchTransactionLog = async () => {
    try {
      const response = await axios.get('/api/transaction/all'); // API to get all transactions
      setTransactionLog(response.data);
    } catch (error) {
      setError('Failed to fetch transactions');
    }
  };

  // Add a new artist and create an associated investor account
  const handleAddArtist = async (e) => {
    e.preventDefault();

    try {
      // Step 1: Create an investor for the artist
      const investorResponse = await axios.post('/api/investors', { name });
      const investor = investorResponse.data;

      // Step 2: Create the artist with the associated investorId, sharesAvailable, and pricePerShare
      const newArtist = {
        name,
        bio,
        investorId: investor._id,  // Link the investor to the artist
        sharesAvailable,  // Include the shares available
        pricePerShare,  // Include the price per share
      };

      await axios.post('/api/artists', newArtist);
      fetchArtists();
      fetchInvestors();

      // Clear form inputs
      setName('');
      setBio('');
      setSharesAvailable(100);  // Reset the shares available field
      setPricePerShare(10);  // Reset the price per share field

      alert('Artist and associated investor created successfully!');
    } catch (error) {
      setError('Failed to add artist and create associated investor');
    }
  };

  // Transfer all shares of an artist to the artist's investor
  const handleTransferShares = async (artistId) => {
    try {
      await axios.post(`/api/artists/${artistId}/transfer-all-shares`);
      alert('Shares transferred successfully!');
      fetchArtists();  // Refresh the list of artists after the transfer
    } catch (error) {
      console.error('Error transferring shares:', error);
      setError('Failed to transfer shares');
    }
  };

  const addInvestor = async () => {
    try {
      const response = await axios.post('/api/investors', { name: newInvestorName });
      setInvestors([...investors, response.data]);
      setNewInvestorName('');
    } catch (error) {
      setError('Error adding investor');
    }
  };

  const handleDeleteArtist = async (id) => {
    try {
      await axios.delete(`/api/artists/${id}`);
      fetchArtists();
    } catch (error) {
      setError('Failed to delete artist');
    }
  };

  const deleteInvestor = async (id) => {
    try {
      await axios.delete(`/api/investors/${id}`);
      setInvestors(investors.filter(investor => investor._id !== id));
    } catch (error) {
      setError('Error deleting investor');
    }
  };

  // Handle navigation to the artist or investor page
  const handleNavigateToArtist = (event) => {
    const artistId = event.target.value;
    if (artistId) {
      navigate(`/artist/profile/${artistId}`);
    }
  };

  const handleNavigateToInvestor = (event) => {
    const investorId = event.target.value;
    if (investorId) {
      navigate(`/investor/${investorId}`);
    }
  };

  useEffect(() => {
    fetchArtists();
    fetchInvestors();
    fetchTransactionLog(); // Fetch transactions on component mount
  }, []);

  return (
   <div>
      {/* Use the NavbarWithSearch component */}
      <NavbarWithSearch />
    <div className="container mx-auto p-4">
      <h2 className="text-3xl font-bold mb-4">Admin Management</h2>
      {error && <p className="text-red-500">{error}</p>}

    <div className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Adding Artists</h3>
        <form onSubmit={handleAddArtist} className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <input
            type="text"
            placeholder="Artist Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="input input-bordered w-full text-sm h-8"
          />
          <input
            type="text"
            placeholder="Artist Bio"
            value={bio}
            onChange={(e) => setBio(e.target.value)}
            required
            className="input input-bordered w-full text-sm h-8"
          />
          <input
            type="number"
            placeholder="Enter available shares"  // Placeholder instead of default value
            value={sharesAvailable}
            onChange={(e) => setSharesAvailable(e.target.value)}
            required
            className="input input-bordered w-full text-sm h-8"
          />
          <input
            type="number"
            placeholder="Enter price per share (€)"  // Placeholder instead of default value
            value={pricePerShare}
            onChange={(e) => setPricePerShare(e.target.value)}
            required
            className="input input-bordered w-full text-sm h-8"
          />
          <button type="submit" className="btn btn-primary mt-4 md:col-span-2">Add Artist</button>
        </form>
      </div>

      <div className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Existing Artists</h3>
        <div className="overflow-x-auto">
          <table className="table w-full">
            <thead>
              <tr>
                <th>Artist Name</th>
                <th>Bio</th>
                <th>ArtistID</th>
                <th>Shares Available</th>
                <th>Price per Share</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {artists.map((artist) => (
                <tr key={artist._id}>
                  <td>{artist.name}</td>
                  <td>{artist.bio}</td>
                  <td>
                    {/* Link component to navigate to artist detail page */}
                    <Link to={`/artist/profile/${artist._id}`} className="text-blue-500 underline">
                      {artist._id}
                    </Link>
                  </td>
                  <td>{artist.sharesAvailable}</td>
                  <td>€{artist.pricePerShare}</td>
                  <td>
                    <div className="flex space-x-2">
                      <button onClick={() => handleDeleteArtist(artist._id)} className="btn btn-error btn-sm">
                        Delete
                      </button>
                      <button onClick={() => handleTransferShares(artist._id)} className="btn btn-secondary btn-sm">
                        Transfer Shares
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Investor Management</h3>
        <div className="flex items-center space-x-4">
          <input
            type="text"
            value={newInvestorName}
            onChange={(e) => setNewInvestorName(e.target.value)}
            placeholder="Investor Name"
            className="input input-bordered"
          />
          <button onClick={addInvestor} className="btn btn-primary">Add Investor</button>
        </div>
      </div>

      {/* Investors Table */}
      <div className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Existing Investors</h3>
        <div className="overflow-x-auto">
          <table className="table w-full">
            <thead>
              <tr>
                <th>Investor Name</th>
                <th>Investor ID</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {investors.map((investor) => (
                <tr key={investor._id}>
                  <td>{investor.name}</td>
                  <td>
                    {/* Link component to navigate to artist detail page */}
                    <Link to={`/investor/${investor._id}`} className="text-blue-500 underline">
                      {investor._id}
                    </Link>
                  </td>
                  <td>
                    <button onClick={() => deleteInvestor(investor._id)} className="btn btn-error btn-sm">
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Central Transaction Log</h3>
        <div className="overflow-x-auto">
          <table className="table w-full">
            <thead>
              <tr>
                <th>Buyer</th>
                <th>Seller</th>
                <th>Artist</th>
                <th>Shares Traded</th>
                <th>Price per Share</th>
                <th>Total Price</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {transactionLog.map((log) => (
                <tr key={log._id}>
                  <td>{log.buyerId?.name || 'N/A'}</td>
                  <td>{log.sellerId?.name || 'N/A'}</td>
                  <td>{log.artistId?.name || 'N/A'}</td>
                  <td>{log.sharesTraded}</td>
                  <td>{log.pricePerShare}</td>
                  <td>{log.totalPrice}</td>
                  <td>{new Date(log.date).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  );
};

export default AdminPage;





//v0.3 Admin Page with Artist+InvestorID creation + Share tranfer

/*import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AdminPage = () => {
  const [artists, setArtists] = useState([]);
  const [investors, setInvestors] = useState([]);
  const [transactionLog, setTransactionLog] = useState([]); // Central transaction log
  const [error, setError] = useState(null);

  const [name, setName] = useState('');
  const [bio, setBio] = useState('');
  const [sharesAvailable, setSharesAvailable] = useState(100);  // New field for shares available
  const [pricePerShare, setPricePerShare] = useState(10);  // New field for price per share
  const [newInvestorName, setNewInvestorName] = useState('');

  // Fetch artists from the backend
  const fetchArtists = async () => {
    try {
      const response = await axios.get('http://localhost:5000/api/artists');
      setArtists(response.data);
    } catch (error) {
      setError('Failed to fetch artists');
    }
  };

  // Fetch investors from the backend
  const fetchInvestors = async () => {
    try {
      const response = await axios.get('http://localhost:5000/api/investors');
      setInvestors(response.data);
    } catch (error) {
      setError('Failed to fetch investors');
    }
  };

  // Fetch all transactions from the central transaction log
  const fetchTransactionLog = async () => {
    try {
      const response = await axios.get('http://localhost:5000/api/transaction/all'); // API to get all transactions
      setTransactionLog(response.data);
    } catch (error) {
      setError('Failed to fetch transactions');
    }
  };

  // Add a new artist and create an associated investor account
  const handleAddArtist = async (e) => {
    e.preventDefault();

    try {
      // Step 1: Create an investor for the artist
      const investorResponse = await axios.post('http://localhost:5000/api/investors', { name });
      const investor = investorResponse.data;

      // Step 2: Create the artist with the associated investorId, sharesAvailable, and pricePerShare
      const newArtist = {
        name,
        bio,
        investorId: investor._id,  // Link the investor to the artist
        sharesAvailable,  // Include the shares available
        pricePerShare,  // Include the price per share
      };

      await axios.post('http://localhost:5000/api/artists', newArtist);
      fetchArtists();
      fetchInvestors();

      // Clear form inputs
      setName('');
      setBio('');
      setSharesAvailable(100);  // Reset the shares available field
      setPricePerShare(10);  // Reset the price per share field

      alert('Artist and associated investor created successfully!');
    } catch (error) {
      setError('Failed to add artist and create associated investor');
    }
  };

  // Transfer all shares of an artist to the artist's investor
  const handleTransferShares = async (artistId) => {
    try {
      await axios.post(`http://localhost:5000/api/artists/${artistId}/transfer-all-shares`);
      alert('Shares transferred successfully!');
      fetchArtists();  // Refresh the list of artists after the transfer
    } catch (error) {
      console.error('Error transferring shares:', error);
      setError('Failed to transfer shares');
    }
  };

  const addInvestor = async () => {
    try {
      const response = await axios.post('http://localhost:5000/api/investors', { name: newInvestorName });
      setInvestors([...investors, response.data]);
      setNewInvestorName('');
    } catch (error) {
      setError('Error adding investor');
    }
  };

  const handleDeleteArtist = async (id) => {
    try {
      await axios.delete(`http://localhost:5000/api/artists/${id}`);
      fetchArtists();
    } catch (error) {
      setError('Failed to delete artist');
    }
  };

  const deleteInvestor = async (id) => {
    try {
      await axios.delete(`/api/investors/${id}`);
      setInvestors(investors.filter(investor => investor._id !== id));
    } catch (error) {
      setError('Error deleting investor');
    }
  };

  useEffect(() => {
    fetchArtists();
    fetchInvestors();
    fetchTransactionLog(); // Fetch transactions on component mount
  }, []);

  return (
    <div>
      <h2>Admin Management</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}

      <h3>Adding Artists</h3>
      <form onSubmit={handleAddArtist}>
        <input
          type="text"
          placeholder="Artist Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Artist Bio"
          value={bio}
          onChange={(e) => setBio(e.target.value)}
          required
        />
        <input
          type="number"
          placeholder="Shares Available"
          value={sharesAvailable}
          onChange={(e) => setSharesAvailable(e.target.value)}
          required
        />
        <input
          type="number"
          placeholder="Price per Share"
          value={pricePerShare}
          onChange={(e) => setPricePerShare(e.target.value)}
          required
        />
        <button type="submit">Add Artist</button>
      </form>

      <h3>Existing Artists</h3>
      <ul>
        {artists.map((artist) => (
          <li key={artist._id}>
            <strong>{artist.name}</strong> - {artist.bio} <br />
            <em>ID: {artist._id}</em> 
            <br />
            <button onClick={() => handleDeleteArtist(artist._id)}>Delete</button>
            <button onClick={() => handleTransferShares(artist._id)}>Transfer Shares</button> 
          </li>
        ))}
      </ul>

      <h3>Investor Management</h3>
      <input
        type="text"
        value={newInvestorName}
        onChange={(e) => setNewInvestorName(e.target.value)}
        placeholder="Investor Name"
      />
      <button onClick={addInvestor}>Add Investor</button>

      <h3>Investors</h3>
      <ul>
        {investors.map(investor => (
          <li key={investor._id}>
            {investor.name} (ID: {investor._id})
            <button onClick={() => deleteInvestor(investor._id)}>Delete</button>
          </li>
        ))}
      </ul>

      <h3>Central Transaction Log</h3>
      <table>
        <thead>
          <tr>
            <th>Buyer</th>
            <th>Seller</th>
            <th>Artist</th>
            <th>Shares Traded</th>
            <th>Price per Share</th>
            <th>Total Price</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {transactionLog.map((log) => (
            <tr key={log._id}>
              <td>{log.buyerId?.name || 'N/A'}</td>
              <td>{log.sellerId?.name || 'N/A'}</td>
              <td>{log.artistId?.name || 'N/A'}</td>
              <td>{log.sharesTraded}</td>
              <td>{log.pricePerShare}</td>
              <td>{log.totalPrice}</td>
              <td>{new Date(log.date).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminPage;*/


//v0.2 Admin Page with Artist+InvestorID creation

/*import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AdminPage = () => {
  const [artists, setArtists] = useState([]);
  const [investors, setInvestors] = useState([]);
  const [transactionLog, setTransactionLog] = useState([]); // Central transaction log
  const [error, setError] = useState(null);

  const [name, setName] = useState('');
  const [bio, setBio] = useState('');
  const [sharesAvailable, setSharesAvailable] = useState(100);  // New field for shares available
  const [pricePerShare, setPricePerShare] = useState(10);  // New field for price per share
  const [newInvestorName, setNewInvestorName] = useState('');

  // Fetch artists from the backend
  const fetchArtists = async () => {
    try {
      const response = await axios.get('http://localhost:5000/api/artists');
      setArtists(response.data);
    } catch (error) {
      setError('Failed to fetch artists');
    }
  };

  // Fetch investors from the backend
  const fetchInvestors = async () => {
    try {
      const response = await axios.get('http://localhost:5000/api/investors');
      setInvestors(response.data);
    } catch (error) {
      setError('Failed to fetch investors');
    }
  };

  // Fetch all transactions from the central transaction log
  const fetchTransactionLog = async () => {
    try {
      const response = await axios.get('http://localhost:5000/api/transaction/all'); // API to get all transactions
      setTransactionLog(response.data);
    } catch (error) {
      setError('Failed to fetch transactions');
    }
  };

  // Add a new artist and create an associated investor account
  const handleAddArtist = async (e) => {
    e.preventDefault();

    try {
      // Step 1: Create an investor for the artist
      const investorResponse = await axios.post('http://localhost:5000/api/investors', { name });
      const investor = investorResponse.data;

      // Step 2: Create the artist with the associated investorId, sharesAvailable, and pricePerShare
      const newArtist = {
        name,
        bio,
        investorId: investor._id,  // Link the investor to the artist
        sharesAvailable,  // Include the shares available
        pricePerShare,  // Include the price per share
      };

      await axios.post('http://localhost:5000/api/artists', newArtist);
      fetchArtists();
      fetchInvestors();

      // Clear form inputs
      setName('');
      setBio('');
      setSharesAvailable(100);  // Reset the shares available field
      setPricePerShare(10);  // Reset the price per share field

      alert('Artist and associated investor created successfully!');
    } catch (error) {
      setError('Failed to add artist and create associated investor');
    }
  };

  const addInvestor = async () => {
    try {
      const response = await axios.post('http://localhost:5000/api/investors', { name: newInvestorName });
      setInvestors([...investors, response.data]);
      setNewInvestorName('');
    } catch (error) {
      setError('Error adding investor');
    }
  };

  const handleDeleteArtist = async (id) => {
    try {
      await axios.delete(`http://localhost:5000/api/artists/${id}`);
      fetchArtists();
    } catch (error) {
      setError('Failed to delete artist');
    }
  };

  const deleteInvestor = async (id) => {
    try {
      await axios.delete(`/api/investors/${id}`);
      setInvestors(investors.filter(investor => investor._id !== id));
    } catch (error) {
      setError('Error deleting investor');
    }
  };

  useEffect(() => {
    fetchArtists();
    fetchInvestors();
    fetchTransactionLog(); // Fetch transactions on component mount
  }, []);

  return (
    <div>
      <h2>Admin Management</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}

        <form onSubmit={handleAddArtist}>
        <input
          type="text"
          placeholder="Artist Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Artist Bio"
          value={bio}
          onChange={(e) => setBio(e.target.value)}
          required
        />
        <input
          type="number"
          placeholder="Shares Available"
          value={sharesAvailable}
          onChange={(e) => setSharesAvailable(e.target.value)}
          required
        />
        <input
          type="number"
          placeholder="Price per Share"
          value={pricePerShare}
          onChange={(e) => setPricePerShare(e.target.value)}
          required
        />
        <button type="submit">Add Artist</button>
      </form>

      <h3>Existing Artists</h3>
      <ul>
        {artists.map((artist) => (
          <li key={artist._id}>
            <strong>{artist.name}</strong> - {artist.bio} <br />
            <em>ID: {artist._id}</em>
            <button onClick={() => handleDeleteArtist(artist._id)}>Delete</button>
          </li>
        ))}
      </ul>

      <h3>Investor Management</h3>
      <input
        type="text"
        value={newInvestorName}
        onChange={(e) => setNewInvestorName(e.target.value)}
        placeholder="Investor Name"
      />
      <button onClick={addInvestor}>Add Investor</button>

      <h3>Investors</h3>
      <ul>
        {investors.map(investor => (
          <li key={investor._id}>
            {investor.name} (ID: {investor._id})
            <button onClick={() => deleteInvestor(investor._id)}>Delete</button>
          </li>
        ))}
      </ul>

      <h3>Central Transaction Log</h3>
      <table>
        <thead>
          <tr>
            <th>Buyer</th>
            <th>Seller</th>
            <th>Artist</th>
            <th>Shares Traded</th>
            <th>Price per Share</th>
            <th>Total Price</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {transactionLog.map((log) => (
            <tr key={log._id}>
              <td>{log.buyerId?.name || 'N/A'}</td>
              <td>{log.sellerId?.name || 'N/A'}</td>
              <td>{log.artistId?.name || 'N/A'}</td>
              <td>{log.sharesTraded}</td>
              <td>{log.pricePerShare}</td>
              <td>{log.totalPrice}</td>
              <td>{new Date(log.date).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminPage;*/







//v0.1 Admin Page with single step artist creation
/*import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AdminPage = () => {
  const [artists, setArtists] = useState([]);
  const [investors, setInvestors] = useState([]);
  const [transactionLog, setTransactionLog] = useState([]); // Central transaction log
  const [error, setError] = useState(null);
  
  const [name, setName] = useState('');
  const [bio, setBio] = useState('');
  const [sharesAvailable, setSharesAvailable] = useState(100);
  const [pricePerShare, setPricePerShare] = useState(10);
  const [newInvestorName, setNewInvestorName] = useState('');
  

  // Fetch artists from the backend
  const fetchArtists = async () => {
    try {
      const response = await axios.get('http://localhost:5000/api/artists');
      setArtists(response.data);
    } catch (error) {
      setError('Failed to fetch artists');
    }
  };

  // Fetch investors from the backend
  const fetchInvestors = async () => {
    try {
      const response = await axios.get('http://localhost:5000/api/investors');
      setInvestors(response.data);
    } catch (error) {
      setError('Failed to fetch investors');
    }
  };

  // Fetch all transactions from the central transaction log
  const fetchTransactionLog = async () => {
    try {
      const response = await axios.get('http://localhost:5000/api/transaction/all'); // API to get all transactions
      setTransactionLog(response.data);
    } catch (error) {
      setError('Failed to fetch transactions');
    }
  };

  // Add a new artist
  const handleAddArtist = async (e) => {
    e.preventDefault();
    const newArtist = {
      name,
      bio,
      sharesAvailable,
      pricePerShare,
    };

    try {
      await axios.post('http://localhost:5000/api/artists', newArtist);
      fetchArtists();
      setName('');
      setBio('');
      setSharesAvailable(100);
      setPricePerShare(10);
    } catch (error) {
      setError('Failed to add artist');
    }
  };

  const addInvestor = async () => {
    try {
      const response = await axios.post('/api/investors', { name: newInvestorName });
      setInvestors([...investors, response.data]);
      setNewInvestorName('');
    } catch (error) {
      setError('Error adding investor');
    }
  };

  const handleDeleteArtist = async (id) => {
    try {
      await axios.delete(`http://localhost:5000/api/artists/${id}`);
      fetchArtists();
    } catch (error) {
      setError('Failed to delete artist');
    }
  };

  const deleteInvestor = async (id) => {
    try {
      await axios.delete(`/api/investors/${id}`);
      setInvestors(investors.filter(investor => investor._id !== id));
    } catch (error) {
      setError('Error deleting investor');
    }
  };

  useEffect(() => {
    fetchArtists();
    fetchInvestors();
    fetchTransactionLog(); // Fetch transactions on component mount
  }, []);

  return (
    <div>
      <h2>Admin Management</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}

      <form onSubmit={handleAddArtist}>
        <input
          type="text"
          placeholder="Artist Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Artist Bio"
          value={bio}
          onChange={(e) => setBio(e.target.value)}
          required
        />
        <input
          type="number"
          placeholder="Shares Available"
          value={sharesAvailable}
          onChange={(e) => setSharesAvailable(e.target.value)}
          required
        />
        <input
          type="number"
          placeholder="Price per Share"
          value={pricePerShare}
          onChange={(e) => setPricePerShare(e.target.value)}
          required
        />
        <button type="submit">Add Artist</button>
      </form>

      <h3>Existing Artists</h3>
      <ul>
        {artists.map((artist) => (
          <li key={artist._id}>
            <strong>{artist.name}</strong> - {artist.bio} <br />
            <em>ID: {artist._id}</em> 
            <button onClick={() => handleDeleteArtist(artist._id)}>Delete</button>
          </li>
        ))}
      </ul>

      <h3>Investor Management</h3>
      <input
        type="text"
        value={newInvestorName}
        onChange={(e) => setNewInvestorName(e.target.value)}
        placeholder="Investor Name"
      />
      <button onClick={addInvestor}>Add Investor</button>

      <h3>Investors</h3>
      <ul>
        {investors.map(investor => (
          <li key={investor._id}>
            {investor.name} (ID: {investor._id})
            <button onClick={() => deleteInvestor(investor._id)}>Delete</button>
          </li>
        ))}
      </ul>

      <h3>Central Transaction Log</h3>
      <table>
        <thead>
          <tr>
            <th>Buyer</th>
            <th>Seller</th>
            <th>Artist</th>
            <th>Shares Traded</th>
            <th>Price per Share</th>
            <th>Total Price</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {transactionLog.map((log) => (
            <tr key={log._id}>
              <td>{log.buyerId?.name || 'N/A'}</td>
              <td>{log.sellerId?.name || 'N/A'}</td>
              <td>{log.artistId?.name || 'N/A'}</td>
              <td>{log.sharesTraded}</td>
              <td>{log.pricePerShare}</td>
              <td>{log.totalPrice}</td>
              <td>{new Date(log.date).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminPage;*/





