//v1.0 OrderBook with first UI

import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Orders = () => {
  const [buyOrders, setBuyOrders] = useState([]);
  const [sellOrders, setSellOrders] = useState([]);
  const [error, setError] = useState(null);

  // Fetch orders from the backend when the component mounts
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get('/api/orders/all'); // API route matches your backend
        console.log('API Response:', response.data); // Log response to ensure correct data format

        const { buyOrders, sellOrders } = response.data; // Destructure the response to get buy and sell orders
        setBuyOrders(buyOrders); // Set the buy orders
        setSellOrders(sellOrders); // Set the sell orders
      } catch (err) {
        console.error('Error fetching orders:', err);
        setError('Failed to fetch orders');
      }
    };

    fetchOrders();
  }, []);

  if (error) return <p className="text-red-500">{error}</p>;

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">Order Book</h1>

      {/* Grid layout for side-by-side tables */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Buy Orders Table */}
        <div>
          <h2 className="text-2xl font-semibold mb-4">Buy Orders</h2>
          <div className="overflow-x-auto">
            <table className="table w-full table-zebra">
              <thead>
                <tr>
                  <th>Investor</th>
                  <th>Artist</th>
                  <th>Artist ID</th>
                  <th>Shares</th>
                  <th>Price (€)</th>
                </tr>
              </thead>
              <tbody>
                {buyOrders.length > 0 ? (
                  buyOrders.map((order, index) => (
                    <tr key={index}>
                      <td>{order.investor ? order.investor.name : 'Unknown Investor'}</td>
                      <td>{order.artist ? order.artist.name : 'Unknown Artist'}</td>
                      <td>{order.artist ? order.artist._id : 'N/A'}</td>
                      <td>{order.shares}</td>
                      <td>{order.price}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center">No Buy Orders</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* Sell Orders Table */}
        <div>
          <h2 className="text-2xl font-semibold mb-4">Sell Orders</h2>
          <div className="overflow-x-auto">
            <table className="table w-full table-zebra">
              <thead>
                <tr>
                  <th>Investor</th>
                  <th>Artist</th>
                  <th>Artist ID</th>
                  <th>Shares</th>
                  <th>Price (€)</th>
                </tr>
              </thead>
              <tbody>
                {sellOrders.length > 0 ? (
                  sellOrders.map((order, index) => (
                    <tr key={index}>
                      <td>{order.investor ? order.investor.name : 'Unknown Investor'}</td>
                      <td>{order.artist ? order.artist.name : 'Unknown Artist'}</td>
                      <td>{order.artist ? order.artist._id : 'N/A'}</td>
                      <td>{order.shares}</td>
                      <td>{order.price}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center">No Sell Orders</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;




