//v1.0 APP with first UI

import React, { useEffect, useState, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import PriceChart from '../components/charts/PriceChart';
import NavbarWithSearch from '../components/navbars/navbar';


const ArtistProfilePage = () => {
  const { id } = useParams();
  const [artist, setArtist] = useState(null);
  const [sharesAvailable, setSharesAvailable] = useState(0);
  const [pricePerShare, setPricePerShare] = useState(0);
  const [artistName, setArtistName] = useState('');
  const [bio, setBio] = useState('');
  const [error, setError] = useState(null);

  const fetchArtist = useCallback(async () => {
    try {
      const response = await axios.get(`/api/artists/profile/${id}`);
      setArtist(response.data);
      setSharesAvailable(response.data.sharesAvailable);
      setPricePerShare(response.data.pricePerShare);
      setArtistName(response.data.name);
      setBio(response.data.bio);
    } catch (err) {
      setError('Failed to fetch artist');
    }
  }, [id]);

  useEffect(() => {
    fetchArtist();
  }, [fetchArtist]);

  const handleInitialOffering = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`/api/artists/profile/${id}`, {
        sharesAvailable,
        pricePerShare
      });
      setArtist(response.data);
      alert('Initial offering set successfully');
    } catch (err) {
      setError('Error setting initial offering');
    }
  };

  const handleArtistInfoUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`/api/artists/profile/${id}`, {
        name: artistName,
        bio: bio
      });
      setArtist(response.data);
      alert('Artist information updated successfully');
    } catch (err) {
      setError('Error updating artist information');
    }
  };

  const handleTransferShares = async () => {
    try {
      await axios.post(`/api/artists/${id}/transfer-all-shares`);
      alert('Shares transferred successfully!');
      fetchArtist();
    } catch (error) {
      setError('Failed to transfer shares');
    }
  };

  if (error) return <p className="text-red-500">{error}</p>;
  if (!artist) return <p>Loading...</p>;

  return (
   <div>
      {/* Use the NavbarWithSearch component */}
      <NavbarWithSearch />

    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Artist Profile</h1>
      <h2 className="text-lg text-lg font-semibold">{artist.name}</h2>
      <h3 className="text-lg text-xs font-semibold">ID: {artist._id}</h3>
    </div>


    <div className="container mx-auto p-4">
      <h1 className="text-lg font-semibold">Current Share Price: €{artist.pricePerShare}</h1>
      <h2 className="text-lg font-semibold">Shares Available: {artist.sharesAvailable}</h2>
      

        {artist.priceHistory && artist.priceHistory.length > 0 ? (
        <PriceChart priceHistory={artist.priceHistory} />
      ) : (
        <p>No price history available</p>
      )}

      {artist.investorId && (
        <div className="my-4">
          <h3 className="text-xl font-semibold">Artist's Investor Profile</h3>
          <Link to={`/investor/${artist.investorId}`} className="btn btn-primary fixed bottom-4 left-4 z-50">Go to Investor Page</Link>
        </div>
      )}

      <h3 className="text-lg font-semibold mb-2">Update Artist Information</h3>
      <form onSubmit={handleArtistInfoUpdate} className="grid grid-cols-1 gap-4 mb-4">
        <input
          type="text"
          value={artistName}
          onChange={(e) => setArtistName(e.target.value)}
          className="input input-bordered"
          placeholder="Artist Name"
          required
        />
        <textarea
          value={bio}
          onChange={(e) => setBio(e.target.value)}
          className="textarea textarea-bordered"
          placeholder="Artist Bio"
          required
        />
        <button type="submit" className="btn btn-primary">Update Artist Info</button>
      </form>

      <h3 className="text-lg font-semibold mb-2">Update Initial Share Offering</h3>
      <form onSubmit={handleInitialOffering} className="grid grid-cols-1 gap-4 mb-4">
        <input
          type="number"
          value={sharesAvailable}
          onChange={(e) => setSharesAvailable(e.target.value)}
          className="input input-bordered"
          placeholder="Shares Available"
          required
        />
        <input
          type="number"
          value={pricePerShare}
          onChange={(e) => setPricePerShare(e.target.value)}
          className="input input-bordered"
          placeholder="Price per Share (€)"
          required
        />
        <button type="submit" className="btn btn-primary">Update Initial Shares</button>
      </form>

      <h3 className="text-lg font-semibold mb-4">Transfer Shares to Investor Portfolio</h3>
      <button onClick={handleTransferShares} className="btn btn-secondary mb-4">Transfer Shares to Wallet</button>

     </div>
    </div>
  );
};

export default ArtistProfilePage;


