import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto'; // Import without creating an unused variable

const PortfolioValueChart = ({ portfolioValueHistory }) => {
  const chartData = {
    labels: portfolioValueHistory.map(entry => entry.date), // x-axis: dates
    datasets: [
      {
        label: 'Portfolio Value',
        data: portfolioValueHistory.map(entry => entry.value), // y-axis: portfolio values
        fill: false,
        borderColor: 'rgba(0.49, 0.31, 275, 1)', // Line color for portfolio value
        tension: 0.1, // Smooth line
        pointRadius: 0,
        pointHoverRadius: 5,

      },
    ],
  };

  const chartOptions = {
    responsive: true, // Makes the chart responsive
    maintainAspectRatio: false, // Allows you to control the size
    scales: {
      x: {
        display: true,
        grid: {
          display: false, // Hides the gridlines on the x-axis
        },
        ticks: {
          maxTicksLimit: 6, // Limits the number of x-axis labels
          maxRotation: 0, // Prevents rotation of labels
          minRotation: 0,
        },
      },
      y: {
        display: true,
        grid: {
          display: false, // Hides the gridlines on the y-axis
        },
        ticks: {
          beginAtZero: true, // Ensures the chart starts at 0
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hides the chart legend
      },
    },
  };

  return (
    <div className="w-full h-32"> {/* Adjust chart size */}
      <Line data={chartData} options={chartOptions} />
    </div>
  );
};

export default PortfolioValueChart;
