//v1.0 LiveSearch with UI

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const ArtistSearch = () => {
  const [searchTerm, setSearchTerm] = useState(''); // Store the current input value
  const [searchResults, setSearchResults] = useState([]); // Store the search results
  const [showDropdown, setShowDropdown] = useState(false); // Toggle dropdown visibility

  // Fetch search results as the user types
  useEffect(() => {
    const fetchSearchResults = async () => {
      if (searchTerm === '') {
        setSearchResults([]); // Clear results if search term is empty
        setShowDropdown(false); // Hide dropdown when search term is empty
        return;
      }

      try {
        const response = await axios.get(`/api/artists/search/artists?q=${searchTerm}`);
        setSearchResults(response.data);
        setShowDropdown(true); // Show dropdown when results are available
      } catch (error) {
        console.error('Error fetching search results:', error);
        setShowDropdown(false); // Hide dropdown on error
      }
    };

    fetchSearchResults(); // Fetch search results immediately whenever the search term changes
  }, [searchTerm]);

  // Handle input change and trigger search immediately
  const handleInputChange = (e) => {
    setSearchTerm(e.target.value); // Update the search term state
  };

  // Handle click outside of dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.dropdown')) {
        setShowDropdown(false); // Close dropdown when clicking outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative dropdown">
      <input
        type="text"
        value={searchTerm}
        onChange={handleInputChange} // Trigger search on every input change
        placeholder="Search for artists..."
        className="input input-bordered py-0 h-8 border-0 bg-gray-100 placeholder:font-semibold text-xs w-full max-w-xs"
      />

      {searchTerm && showDropdown && (
        <ul className="dropdown-content absolute z-10 w-full bg-white shadow-sm rounded mt-2 max-h-60 overflow-y-auto">
          {searchResults.length > 0 ? (
            searchResults.map((artist) => (
              <li key={artist._id} className="px-4 text-xs py-2 hover:bg-gray-200">
                <Link to={`/artist/detail/${artist._id}`} onClick={() => setShowDropdown(false)}>
                  {artist.name} - Latest Price: €
                  {artist.priceHistory.length > 0
                    ? artist.priceHistory[artist.priceHistory.length - 1].price
                    : 'N/A'}
                </Link>
              </li>
            ))
          ) : (
            <li className="px-4 text-xs py-2 text-gray-500">No results found</li> // Show "No results found"
          )}
        </ul>
      )}
    </div>
  );
};

export default ArtistSearch;



/*
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const ArtistSearch = () => {
  const [searchTerm, setSearchTerm] = useState(''); // Store the current input value
  const [searchResults, setSearchResults] = useState([]); // Store the search results
  const [showDropdown, setShowDropdown] = useState(false); // Toggle dropdown visibility
  const [loading, setLoading] = useState(false); // Loading state to handle "No results" flashing

  // Fetch search results as the user types
  useEffect(() => {
    const fetchSearchResults = async () => {
      if (searchTerm === '') {
        setSearchResults([]); // Clear results if search term is empty
        setShowDropdown(false); // Hide dropdown when search term is empty
        return;
      }

      setLoading(true); // Start loading when fetching begins
      try {
        const response = await axios.get(`/api/artists/search/artists?q=${searchTerm}`);
        setSearchResults(response.data);
        setShowDropdown(true); // Show dropdown when results are available
      } catch (error) {
        console.error('Error fetching search results:', error);
      } finally {
        setLoading(false); // Set loading to false after fetching is done
      }
    };

    fetchSearchResults(); // Fetch search results immediately whenever the search term changes
  }, [searchTerm]);

  // Handle input change and trigger search immediately
  const handleInputChange = (e) => {
    setSearchTerm(e.target.value); // Update the search term state
  };

  // Handle click outside of dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.dropdown')) {
        setShowDropdown(false); // Close dropdown when clicking outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative dropdown"> 
      <input
        type="text"
        value={searchTerm}
        onChange={handleInputChange} // Trigger search on every input change
        placeholder="Search for artists..."
        className="input input-bordered py-0 h-8 border-0 bg-gray-100 placeholder:font-semibold text-xs w-full max-w-xs"
      />

      
      {showDropdown && searchResults.length > 0 && (
        <ul className="dropdown-content absolute z-10 w-full bg-white shadow-lg rounded mt-2 max-h-60 overflow-y-auto">
          {searchResults.map((artist) => (
            <li key={artist._id} className="px-4 text-xs py-2 hover:bg-gray-200">
              <Link to={`/artist/${artist._id}`} onClick={() => setShowDropdown(false)}>
                {artist.name} - Latest Price: €
                {artist.priceHistory.length > 0
                  ? artist.priceHistory[artist.priceHistory.length - 1].price
                  : 'N/A'}
              </Link>
            </li>
          ))}
        </ul>
      )}

      
      {!loading && searchTerm && searchResults.length === 0 && (
        <p className="text-gray-500 text-xs mt-2">No results found</p>
      )}
    </div>
  );
};

export default ArtistSearch;*/







/*import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const ArtistSearch = () => {
  const [searchTerm, setSearchTerm] = useState(''); // Store the current input value
  const [searchResults, setSearchResults] = useState([]); // Store the search results
  const [showDropdown, setShowDropdown] = useState(false); // Toggle dropdown visibility

  // Fetch search results as the user types
  useEffect(() => {
    const fetchSearchResults = async () => {
      if (searchTerm === '') {
        setSearchResults([]); // Clear results if search term is empty
        setShowDropdown(false); // Hide dropdown when search term is empty
        return;
      }


      try {
        const response = await axios.get(`/api/artists/search/artists?q=${searchTerm}`);
        setSearchResults(response.data);
        setShowDropdown(true); // Show dropdown when results are available
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    };

    fetchSearchResults(); // Fetch search results immediately whenever the search term changes
  }, [searchTerm]);

  // Handle input change and trigger search immediately
  const handleInputChange = (e) => {
    setSearchTerm(e.target.value); // Update the search term state
  };

  // Handle click outside of dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.dropdown')) {
        setShowDropdown(false); // Close dropdown when clicking outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="flexible dropdown">
      <input
        type="text"
        value={searchTerm}
        onChange={handleInputChange} // Trigger search on every input change
        placeholder="Search for artists..."
        className="input input-bordered py-0 h-8 border-0 bg-gray-100 placeholder:font-semibold text-xs w-full max-w-xs"
      />

      {showDropdown && searchResults.length > 0 && (
        <ul className="dropdown-content absolute z-10 w-full bg-white shadow-lg rounded mt-4 max-h-60 overflow-y-auto">
          {searchResults.map((artist) => (
            <li key={artist._id} className="px-4 text-xs py-2 hover:bg-gray-200">
              <Link to={`/artist/${artist._id}`} onClick={() => setShowDropdown(false)}>
                {artist.name} - Latest Price: €
                {artist.priceHistory.length > 0
                  ? artist.priceHistory[artist.priceHistory.length - 1].price
                  : 'N/A'}
              </Link>
            </li>
          ))}
        </ul>
      )}

      {searchTerm && searchResults.length === 0 && (
        <p className="text-gray-500 text-xs mt-2">No results found</p>
      )}
    </div>
  );
};

export default ArtistSearch;*/
